<template>
  <div></div>
</template>

<script>
export default {
  title: '이메일무단수집거부',
};
</script>

<style>
</style>
